<template>
  <div>
    
    <v-form v-model="valid">
      <block v-if="loading"/>
        <v-card>
          <v-tabs v-model="tab" dark background-color="primary" icons-and-text>
            <v-tabs-slider color="red"></v-tabs-slider>
            <v-tab href="#tab-1"  
            v-if="$store.getters['rolesUser/getCustomRole'](50)"
            @click="cleanData">
              AÑADIR TRASPASO
              <v-icon>mdi-archive-plus</v-icon>
            </v-tab>
            <v-tab href="#tab-2" 
            v-if="$store.getters['rolesUser/getCustomRole'](52)"
            @click="cleanData">
              AÑADIR SOLICITUD DE TRASPASO
              <v-icon>mdi-archive-edit</v-icon>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <!-- Start of add transfer -->
            <v-tab-item value="tab-1" v-if="$store.getters['rolesUser/getCustomRole'](40)">
              <v-card flat class="pa-1">
               
                  <div class="mt-0" style="
                      margin-top: 1.5rem;
                      display: flex;
                      justify-content: center;
                    ">
                    <v-col class="box" cols="12" md="12">
                      <v-row class="ml-2 mr-2" style="margin-top: 0.3rem">
                        <v-col cols="12" class="tabsNh">
                          <v-col cols="12" style="
                            display: flex;
                            justify-content: space-between;
                          ">
                            <div></div>
                            <div>
                              <v-btn small class="btnclean mr-1" @click.prevent="cleanData">
                                <v-icon>mdi-broom</v-icon>
                              </v-btn>
                              <v-btn v-if="$store.getters['rolesUser/getCustomRole'](51)" small class="mr-1 btnsave" @click.prevent.stop="addNewTransfer">
                                <v-icon>mdi-content-save</v-icon>
                              </v-btn>
                            </div>
                          </v-col>
                        </v-col>
                      </v-row>

                      <div class=" flex-wrap align-self-center justify-space-between mb-4 pa-3 my-6">
                        <!-- Start of Form Add Transfer-->
                        <template>
                          <v-row>
                            <v-col cols="12" md="3" sm="8" lg="2" xl="2">
                              <date-picker label="Fecha: " @dateSelected="(param) => (date = param)" v-model="date"
                                :value="date"></date-picker>
                            </v-col>
                              <v-col cols="12" md="3" sm="6" lg="4" xl="4">
                                <v-autocomplete attach :items="filteredWarehousesForOrigin" label="Almacén (Origen): "
                                  @change="getItemsFromWarehouseOrigin()" v-model="form.warehouseOrigin"
                                  item-text="Warehouse" item-value="ID_Warehouse"
                                  :disabled="productsToShow.length > 0 || productsToShowFilter.length > 0" 
                                  outlined dense></v-autocomplete>
                              </v-col>

                              <v-col cols="12" md="3" sm="6" lg="4" xl="4">
                                <v-autocomplete attach :items="filteredWarehousesForDestination"
                                  label="Almacén (Destino): " v-model="form.warehouseDestination" item-text="Warehouse"
                                  item-value="ID_Warehouse" outlined dense></v-autocomplete>
                              </v-col>
                              <v-col cols="12" md="2" sm="12" lg="2" xl="2" align="center">
                                <v-btn :disabled="form.warehouseOrigin === undefined" @click.prevent="
                                  showPrices = !showPrices
                                  " class="btn-add mb-2" ><v-icon>mdi-plus</v-icon>&nbsp;&nbsp;Agregar producto</v-btn>
                              </v-col>
                          </v-row>
                          <v-row>
                              <v-col cols="12" md="12" class="mt-3">
                                <h3 :class="form.notes ? 'tabsContent' : 'tabs'" @click.prevent="showNotes = !showNotes"
                                  style="text-align: center;"> <v-icon class="icon-color">mdi-comment</v-icon> Comentarios

                                </h3>
                              </v-col>
                            </v-row>
                          <v-row class="mt-8">
                            <div><p><b>Indicación:</b> Editar celda (Unidades a transferir) para especificar la cantidad a transferir al almacén destino</p></div>
                              <!--v-col cols="12" class="d-flex flex-row-reverse">
                                <v-btn class="btnsave" @click="() => calculateStockResult('dxDataGridTransfer')">Calcular Nueva existencia</v-btn>
                              </v-col-->
                          </v-row>
                        </template>
                        <!-- End of Form Add Transfer -->
                        <!-- Datagrid Transfer Start -->
                        <GeneralFilter class="background border-box" entity="producto"
                        :filterEndpoint="filterTransferProduct" :search="search"
                        @emptyFilter="getProductsToShow" @filtered="filterHandler">
                          <DxDataGrid  style="width: 100%;" ref="dxDataGridTransfer" :scrolling="{ useNative: true }" :data-source="productsToShow" key-expr="ID_Item" @exporting="onExporting">
                            <DxExport :enabled="true" />
                            <DxEditing :allow-updating="true" :select-text-on-edit-start="true" start-edit-action="click" mode="cell" />
                            <DxColumn :width=" columnWidth" :allow-editing="false" data-field="ID_Item" caption="ID de Producto"></DxColumn>
                            <DxColumn :width="columnWidth" :allow-editing="false" data-field="ItemName" caption="Nombre de Producto"></DxColumn>
                            <DxColumn :width="columnWidth" :allow-editing="false" data-field="WarehouseName" caption="Almacén (Origen)"></DxColumn>
                            <DxColumn  :width="columnWidth" :allow-editing="false" data-field="ItemCurrentQuantity" caption="Existencia actual"></DxColumn>
                            <DxColumn data-field="Amount" header-cell-template="headerNameAmount" data-type="number">
                              <DxValidator>
                              <DxPatternRule :pattern="itemQtyPattern"
                                message="La cantidad del producto solo puede contener numeros enteros mayores a 0" />
                              </DxValidator>
                              <DxAsyncRule :validation-callback="handleStockAndQty"
                                message="La cantidad del producto no puede exceder el stock actual en el almacen" />
                            </DxColumn>
                            <template #headerNameAmount="{}"><div>Unidades a<br/>transferir</div></template>
                            <DxColumn :width="columnWidth" :allow-editing="false" cell-template="stock-template" caption="Nueva existencia"></DxColumn>
                            <template #stock-template="{ data }">
                              {{ calculateCellValue(data.data) }}
                            </template>
                            <DxColumn   :width="columnWidth" cell-template="lote-template" caption="Lote"></DxColumn>
                            <template #lote-template="{ data }">
                              {{ data.data.ID_ItemBatch ? data.data.ID_ItemBatch : 'Por defecto' }}
                            </template>

                            <DxColumn :width="columnWidth" :allow-editing="false" data-field="warehouseAtDestination" caption="Almacén (Destino)"></DxColumn>
                            <DxColumn :width="columnWidth" :allow-editing="false" data-field="ItemCurrentQuantityAtDestination" caption="Existencia actual"></DxColumn>
                            <DxColumn :width="columnWidth" cell-template="stock-template2" caption="Nueva existencia"></DxColumn>
                            <template #stock-template2="{ data }">
                              {{ calculateCellValue2(data.data) }}
                            </template>
                            <DxColumn   :width="columnWidth" cell-template="lote-template2" caption="Lote"></DxColumn>
                            <template #lote-template2="{ data }">
                              {{ data.data.ID_ItemBatchAtDestination ? data.data.ID_ItemBatchAtDestination : 'Por defecto' }}
                            </template>


                            <DxColumn :width="40" cell-template="show-template" caption="">
                            </DxColumn>
                            <template #show-template="{ data }">
                              <a href="#" class="mx-1" @click.prevent.stop="removeRow(data)">
                                <v-icon color="error">mdi-delete</v-icon>
                              </a>
                            </template>
                          </DxDataGrid>
                        </GeneralFilter>  
                        <!-- Datagrid Transfer End -->
                      </div>
                    </v-col>
                  </div>
              
              </v-card>
            </v-tab-item>
            <!-- End of add transfer -->
            <!-- Start of add request transfer -->
            <v-tab-item value="tab-2" v-if="$store.getters['rolesUser/getCustomRole'](40)">
              <v-card flat class="pa-1">
             
                  <v-dialog v-model="showPriceList" width="60%">
                    <v-card class="pa-4">
                      <v-card-title style="
                          margin-top: 0.3rem;
                          margin-bottom: 0.3rem;
                          
                          ;
                        ">
                        <div class="text-h5">Lista de Precios</div>
                        <v-btn small class="btndelete" @click.prevent="showPriceList = false">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-card-title>
                      <v-card-text>
                        <!-- Needs filter -->
                        <DxDataGrid class="" :data-source="priceList" key-expr="id" :show-row-lines="showRowLines"  :scrolling="{ useNative: true }"
                          @exporting="onExportingP" :show-borders="showBorders"
                          :row-alternation-enabled="rowAlternationEnabled">
                          
                          <DxPaging :page-size="8" />
                          <DxExport :enabled="true" />
                          <DxColumn :width="60" cell-template="show-template" caption="">
                          </DxColumn>
                          <DxColumn :width="columnWidth" data-field="date" caption="Fecha de Creación"></DxColumn>
                          <DxColumn :width="columnWidth" data-field="id" caption="Nombre de la Lista de Precios"></DxColumn>
                          <DxColumn :width="columnWidth" v-if="rolesStatus.findIndex(
                            (role) => role.RoleName == 'admin'
                          ) > -1 ||
                            rolesStatus.findIndex(
                              (role) => role.RoleName == 'root'
                            ) > -1
                            " cell-template="actives-show" caption="Activo"></DxColumn>
                          <template #show-template="{ data }">
                            <a href="#" class="mx-1" @click.prevent.stop="
                              showProductOld(data)
                            showPriceList = false
                              ">
                              <v-icon> color="primary"</v-icon>
                            </a>
                          </template>
                          <template #actives-show="{ data }">
                            <div class="d-flex justify-center align-center" style="max-height: 30px">
                              <v-checkbox v-model="data.data.Active" @change="handleChanged(data.data)"></v-checkbox>
                            </div>
                          </template>
                        </DxDataGrid>
                      </v-card-text>
                    </v-card>
                  </v-dialog>

                  <div class="mt-0" style="
                      margin-top: 1.5rem;
                      display: flex;
                      justify-content: center;
                    ">
                    <v-col class="box" cols="12" md="12">
                      <v-row class="ml-2 mr-2" style="margin-top: 0.3rem">
                        <v-col cols="12" class="tabsNh">
                          <v-col cols="12" style="
                              display: flex;
                              justify-content: space-between;
                            ">
                            <div></div>
                            <div>
                              <v-btn small class="btnclean mr-1" @click.prevent="cleanData">
                                <v-icon>mdi-broom</v-icon>
                              </v-btn>
                              <v-btn v-if="$store.getters['rolesUser/getCustomRole'](53)" small class="mr-1 btnsave" @click.prevent.stop="addNewTransferRequest">
                                <v-icon>mdi-content-save</v-icon>
                              </v-btn>
                            </div>
                          </v-col>
                        </v-col>
                      </v-row>

                      <div class=" flex-wrap align-self-center justify-space-between mb-4 pa-3 my-6">
                        <!-- Start of Form Add Transfer-->
                        <template>
                          <v-col>
                            <v-row cols="12">
                              <v-col cols="12" md="3">
                                <date-picker label="Fecha: " @dateSelected="(param) => (date = param)" v-model="date"
                                  :value="date"></date-picker>
                              </v-col>

                              <v-col cols="12" md="3">
                                <v-autocomplete attach :items="filteredWarehousesForOrigin" v-model="form.warehouseOrigin"
                                  label="Almacén (Origen): " @change="getItemsFromWarehouseOrigin()" item-text="Warehouse"
                                  item-value="ID_Warehouse" :disabled="productsToShow.length > 0 || productsToShowFilter.length > 0"
                                  outlined dense></v-autocomplete>
                              </v-col>

                              <v-col cols="12" md="3">
                                <v-autocomplete attach :items="filteredWarehousesForDestination"
                                  v-model="form.warehouseDestination" label="Almacén (Destino): " item-text="Warehouse"
                                  item-value="ID_Warehouse" outlined dense></v-autocomplete>
                              </v-col>

                              <v-col cols="12" md="3" align="center">
                                <v-btn :disabled="form.warehouseOrigin === undefined" @click.prevent="
                                  showPrices = !showPrices
                                  " class="btn-add"><v-icon>mdi-plus</v-icon>&nbsp;&nbsp;Agregar producto</v-btn>
                              </v-col>

                            </v-row>

                            <v-row cols="12">
                              <v-col cols="12" md="12" class="mt-3">
                                <h3 :class="form.notes ? 'tabsContent' : 'tabs'" @click.prevent="showNotes = !showNotes"
                                  style="text-align: center;"> <v-icon class="icon-color">mdi-comment</v-icon> Comentarios

                                </h3>
                              </v-col>
                            </v-row>
                            <v-row class="mt-8">
                              <div><p><b>Indicación:</b> Editar celda (Unidades a transferir) para especificar la cantidad a transferir al almacén destino</p></div>
                              <!--v-col cols="12" class="d-flex flex-row-reverse">
                                <v-btn class="btnsave" @click="() => calculateStockResult('dxDataGridRequest')">Calcular Nueva existencia</v-btn>
                              </v-col-->
                            </v-row>
                          </v-col>
                        </template>
                        <!-- End of Form Add Transfer -->
                        <!-- Datagrid Transfer Start -->
                        <GeneralFilter class="background border-box" entity="producto"
                        :filterEndpoint="filterTransferProduct" :search="search"
                        @emptyFilter="getProductsToShow" @filtered="filterHandler">
                          <DxDataGrid class="isScrolledByGeneralFilter" ref="dxDataGridRequest" :key="renderN" :data-source="productsToShow" key-expr="ID_Item"
                            @exporting="onExporting">
                            <DxExport :enabled="true" />
                            <DxEditing :allow-updating="true" :select-text-on-edit-start="true" start-edit-action="click"
                              mode="cell" />
                            <DxColumn :width="columnWidth" :allow-editing="false" data-field="ID_Item" caption="ID de Producto">
                            </DxColumn>
                            <DxColumn :width="columnWidth" :allow-editing="false" data-field="ItemName" caption="Nombre de Producto">
                            </DxColumn>
                            <DxColumn :width="columnWidth" :allow-editing="false" data-field="ItemCurrentQuantity" caption="Existencia actual"></DxColumn>
                            <DxColumn :width="columnWidth" data-field="Amount" cell-header-template="headerNameAmount"  data-type="number">
                              <DxPatternRule :pattern="itemQtyPattern"
                                message="La cantidad del producto solo puede contener numeros enteros mayores a 0" />
                              <DxAsyncRule :validation-callback="handleStockAndQty"
                                message="La cantidad del producto no puede exceder el stock actual en el almacen" />
                            </DxColumn>
                            <template #headerNameAmount="{}"><div>Unidades a<br/>transferir</div></template>
                            <DxColumn :width="columnWidth" cell-template="stock-template" caption="Nueva existencia"></DxColumn>
                            <template #stock-template="{ data }">
                              {{ calculateCellValue(data.data) }}
                            </template>
                            <DxColumn   :width="columnWidth" cell-template="lote-template" caption="Lote"></DxColumn>
                            <template #lote-template="{ data }">
                              {{ data.data.ID_ItemBatch ? data.data.ID_ItemBatch : 'Por defecto' }}
                            </template>

                            <DxColumn :width="columnWidth" :allow-editing="false" data-field="warehouseAtDestination" caption="Almacén (Destino)"></DxColumn>
                            <DxColumn  :width="columnWidth" :allow-editing="false" data-field="ItemCurrentQuantityAtDestination" caption="Existencia actual"></DxColumn>
                            <DxColumn   :width="columnWidth" cell-template="stock-template2" caption="Nueva existencia"></DxColumn>
                            <template #stock-template2="{ data }">
                              {{ calculateCellValue2(data.data) }}
                            </template>
                            <DxColumn   :width="columnWidth" cell-template="lote-template2" caption="Lote"></DxColumn>
                            <template #lote-template2="{ data }">
                              {{ data.data.ID_ItemBatchAtDestination ? data.data.ID_ItemBatchAtDestination : 'Por defecto' }}
                            </template>


                            <DxColumn :width="40" cell-template="show-template" caption="">
                            </DxColumn>
                            <template #show-template="{ data }">
                              <a href="#" class="mx-1" @click.prevent.stop="removeRow(data)">
                                <v-icon color="error">mdi-delete</v-icon>
                              </a>
                            </template>
                          </DxDataGrid>
                        </GeneralFilter>  
                        <!-- Datagrid Transfer End -->
                        <!-- Needs filter -->
                        <!-- <DxDataGrid class="isScrolledByGeneralFilter" :data-source="productsToShow" key-expr="ID_Item" :show-row-lines="showRowLines"
                          @exporting="onExportingLP" :show-borders="showBorders"
                          :row-alternation-enabled="rowAlternationEnabled">
                          <DxExport :enabled="true" />
                          <DxEditing :allow-updating="true" :select-text-on-edit-start="true" start-edit-action="click"
                            mode="cell" />
                            <DxColumn data-field="ID_Item" caption="ID de Producto"></DxColumn>
                            <DxColumn data-field="ItemName" caption="Nombre de Producto"></DxColumn>
                            <DxColumn data-field="Amount" :allow-editing="true" caption="Cantidad Solicitada"></DxColumn>
                            <DxColumn cell-template="stock-template" caption="Stock Origen Resultante"></DxColumn>
                            <template #stock-template="{ data }">
                              {{ data.data.ItemCurrentQuantity }} {{ data.data.ItemMeasureUnit }}
                            </template>
                          <DxColumn v-if="rolesStatus.findIndex(
                            (role) => role.RoleName == 'admin'
                          ) > -1 ||
                            rolesStatus.findIndex(
                              (role) => role.RoleName == 'root'
                            ) > -1 ||
                            rolesStatus.findIndex(
                              (role) => role.ID_Role == 45
                            ) > -1
                            " :width="40" cell-template="show-template" caption=""></DxColumn>

                          <template #show-template="{ data }">
                            <a href="#" class="mx-1" @click.prevent.stop="removeRow(data)">
                              <v-icon color="error">mdi-delete</v-icon>
                            </a>
                          </template>

                          <template #product-discount="{ data }">
                            <div class="input-group">
                              <input type="number" v-model="data.data.ItemPriceDiscountAmount"
                                :max="data.data.ItemOriginalPrice" min="0" @keyup.enter="changeDis($event, data)"
                                class="form-control" />

                              <div class="input-group-prepend">
                                <span class="input-group-text">{{
                                  CurrentBranch.Currency
                                }}</span>
                              </div>
                            </div>
                          </template>

                          <template #product-discountpercent="{ data }">
                            <div class="input-group">
                              <input type="number" v-model="data.data.ItemPriceDiscountPercent" class="form-control"
                                @keyup.enter="changeDisP($event, data)" :max="100" :min="0" />
                              <div class="input-group-prepend">
                                <span class="input-group-text">%</span>
                              </div>
                            </div>
                          </template>

                        </DxDataGrid> -->
                      </div>
                    </v-col>
                  </div>
                
              </v-card>
            </v-tab-item>
            <!-- End of add request transfer -->
          </v-tabs-items>
        </v-card>
      </v-form>
  
    <v-dialog v-model="showPrices" width="80%" scrollable>
      <v-card class="pa-4">
        <v-card-title class="text-h5">
          Productos agregados {{ counter }}
        </v-card-title>
        <v-card-text>
          <v-dialog v-model="showFilter" width="800" persistent scrollable>
            <v-card>
              <v-card-text>
                <v-row >
                  <v-col cols="12" md="12" sm="12" style="
                      display: flex;
                      justify-content: space-between;
                      margin-top: 1rem;
                    ">
                    <div class="text-h5">Filtrar Productos</div>
                    <v-btn @click.prevent="clearFilters" class="btnclean">
                      Limpiar Filtros
                    </v-btn>
                  </v-col>

                  <v-col cols="12" md="12" sm="12">
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-autocomplete outlined dense label="proveedor" v-model="filter.providers" :items="warehouses"
                          item-text="ProviderName" attach item-value="ID_Provider"></v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-autocomplete outlined dense label="Familia" v-model="filter.family" :items="families"
                          item-text="name" attach item-value="id"></v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-autocomplete outlined dense label="Categoría" v-model="filter.category"
                          :items="filteredCategories" item-text="name" attach item-value="id"
                          :disabled="!filter.family"></v-autocomplete>
                      </v-col>
                      <v-col>
                        <v-autocomplete outlined dense label="Subcategoría" v-model="filter.subcategory"
                          :items="filteredSubcategories" attach item-text="name" item-value="id"
                          :disabled="!filter.category"></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="12" sm="12" style="
                      display: flex;
                      justify-content: flex-end;
                      margin-top: 0.6rem;
                    ">
                    <v-btn @click.prevent="filterProductsDt" class="btn-add">
                      Aplicar Filtros
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-dialog>
          <div style="display: flex; justify-content: flex-start; padding: 12px">
            <v-btn v-if="showdeselect" class="btn-add" @click="deselectAll">Deseleccionar todas</v-btn>
            <v-btn v-else @click="selectAll" class="btn-add">Seleccionar todas</v-btn>
            &nbsp;&nbsp;<v-btn class="btnsave" @click.prevent="addProducts">Agregar</v-btn>  
          </div>
          <div style="width: 97%;">
            <!-- Hidden -->
            <div v-show="false" style="margin-left: 0.3rem">
              <v-btn class="fs-14 mx-2 btnclean" style="margin-top: 1rem; position: fixed; z-index: 50"
                @click.prevent.stop="showFilter = !showFilter">
                Filtrar</v-btn>
            </div>
            <VersatileFilter :columnsSearch="columsConfig" :disableDates="true"  ref="generalFilter" :search="{}" :columnfilterEndpoint="$API.products.getProductInventoryHistoryByColumns" @emptyFilter="clearInventoryFilter" @filtered="handleInventoryFilter">
              <DxDataGrid class="" :data-source="inventory" key-expr="ID_Item" :show-row-lines="showRowLines" @exporting="onExporting"
                :show-borders="showBorders" :row-alternation-enabled="rowAlternationEnabled" 
                :scrolling="{ useNative: true }"
                      :column-auto-width="true"
                >
                <DxExport :enabled="true" />
    
              
                <DxColumn  cell-template="show-template" caption=""></DxColumn>
                <DxPaging :page-size="5" />
                <DxColumn :width="columnWidth" data-field="ID_Item" caption="ID de Producto/Servicio"></DxColumn>
                <DxColumn :width="columnWidth" data-field="PrincipalBarCode" caption="Código Alterno Principal"></DxColumn>
                <DxColumn :width="columnWidth" data-field="WarehouseName" caption="Almacen"></DxColumn>
                <DxColumn :width="columnWidth" cell-template="stock-template" caption="Existencia"></DxColumn>
                <DxColumn :width="columnWidth" cell-template="lote-template" caption="lote"></DxColumn>
                <DxColumn data-field="ItemName" caption="Nombre de Producto/Servicio"></DxColumn>
                <template #show-template="{ data }">
                  <v-checkbox v-model="data.data.checkbox" class="pt-0" @click="showProduct(data)">
                  </v-checkbox>
                </template>
                <template #stock-template="{ data }">
                  {{ data.data.ItemCurrentQuantity }} {{ data.data.ItemMeasureUnit }}
                </template>
                <template #lote-template="{ data }">
                  {{ data.data.ID_ItemBatch ? data.data.ID_ItemBatch : "Por defecto" }}
                </template>
              </DxDataGrid>
            </VersatileFilter>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog width="60%" v-model="showNotes">
      <v-card class="pa-4">
        <div style="margin-top: .3rem;margin-bottom: .3rem; display: flex;justify-content: flex-end;">
          <v-btn small class="btndelete" @click.prevent="showNotes = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <quill-editor :content="form.notes" :options="quillOptions" @change="onEditorChange($event)"></quill-editor>
      </v-card>
    </v-dialog>

    <alerts v-if="alert.show" v-on:close_alert="closeAlert" v-on:accept_alert="acceptAlert" :properties="alert">
    </alerts>

  </div>
</template>

<script>
import Block from "@/components/Block.vue";

import {
  DxDataGrid,
  DxColumn,
 
  DxPaging,
  DxExport,
  DxEditing,
  DxPatternRule,
  DxAsyncRule,
  DxValidator
} from 'devextreme-vue/data-grid'
import DatePicker from "@/components/DatePicker.vue"
import Alerts from '@/components/Alerts'
import { mapMutations, mapState } from 'vuex'
import { printToExcel } from '@/helpers/printToexcel'
import { quillConfig } from "@/helpers/quillconfig"
// eslint-disable-next-line no-unused-vars
import {fixFormatDate } from '@/helpers/formatDate'
import VersatileFilter from '@/components/VersatileFilter';

import GeneralFilter from '@/components/GeneralFilter.vue'
import { vesatileCriteriaFilter } from '../../../helpers/object'

export default {
  name: "AddTransferC",
  components: {
    DxDataGrid,
    VersatileFilter,
    DxPatternRule,
    DxColumn,
    DxValidator,
    DxAsyncRule,
  
    DxPaging,
    Alerts,
    DxExport,
    DatePicker,
    DxEditing,
    GeneralFilter,
    Block
  },
  props: ['win'],

  data() {
    return {
      alert: {
        type: 'success',
        show: false,
        header: '',
        body: '',
      },
      valid: false,
      loading: false,
      showNotes: false,
      showPrices: false,
      showPriceList: false,
      showRelateClientPrice: false,
      productsprev: [],
      tab: null,
      panel: 0,
      quillOptions: quillConfig,
      checkbox: true,
      discountpercent: 0,
      form: {},
      exportData: false,
      priceList: [],
      showList: false,
      productsToBill: [],
      inventory: [],
      productsToShow: [],
      productsToShowOld: [],
      productsToShowWarehouse: [],
      filterProducts: {},
      items: [],
      filter: {
        family: null,
        category: null,
        subcategory: null,
        providers: null,
      },
      columsConfig: [
                {
                    name: "PR.family",
                    title: "Familia",
                    value: "",
                    type: "select",
                    options: [],
                },
                {
                    name: "PR.category",
                    title: "Categoría",
                    value: "",
                    parent: 'PR.family',
                    type: "select",
                    options: [],
                },
                {
                    name: "PR.subcategory",
                    title: "Subcategoría",
                    value: "",
                    parent: 'PR.category',
                    type: "select",
                    options: [],
                },
      ],
      showRowLines: true,
      showBorders: true,
      rowAlternationEnabled: true,
      business: null,
      branch: null,
      showdeselect: false,
      counter: 0,
      showFilter: false,
      families: [],
      categories: [],
      subcategories: [],
      providers: [],
      renderN: 0,
      customers: [],
      search: {
        filter: null
      },
      date: null,
      selectedClient: {},
      warehouses: [],
      filterWarehouses: {},
      products: [],
      AllData: [],
      typeOfTransfer: ["Pendientes", "Parcialmente servidas", "Servidas", "Finalizadas", "Otros"],
      itemQtyPattern: /^(0|[1-9]\d*)$/,
      transferRequestActive: false,
      requestStatus: null,
      requestId: null,
      productsToShowFilter: [],
      isInventoryFiltered: false,
      inventoryFiltered: [],
      didHaveAllData: false
    }
  },
  watch: {
    transferRequestData: function (newValue, oldValue) {
      
      if (newValue) {
        if(newValue.tab == "request"){
          this.tab = "tab-1"
        }
        this.getInventory()
      }
      console.log(oldValue)
    },
    showPrices: function (newValue, oldValue) {
      if (!newValue) {
        this.inventory.map((element) => {
          element.checkbox = false
        })
        // this.showpriceskey++
        // this.counter = 0
      }
      console.log(oldValue)
    }
  },
  computed: {
    ...mapState(
      "rolesUser",
      {
        rolesStatus: (state) => state.roles,
      })
      ,
    ...mapState({
      currentBranch: 'CurrentBranch',
      transferRequestData: state => state.transferData.transfers,
      reloadState: state => state.transferData.reloadData,
    }),
    columnWidth() {
    
    return this.$vuetify.breakpoint.smAndDown ? '160px' : 'auto !important';
  },
    filteredCategories() {
      return this.filter.family
        ? this.categories.filter(
          (category) => category.id_family == this.filter.family
        )
        : []
    },
    filteredSubcategories() {
      return this.filter.category
        ? this.subcategories.filter(
          (subcategory) => subcategory.id_cat == this.filter.category
        )
        : []
    },
    filteredWarehousesForOrigin() {
      return this.warehouses.filter(
        (warehouse) => warehouse.ID_Warehouse != this.form.warehouseDestination
      )
    },
    filteredWarehousesForDestination() {
      return this.warehouses.filter(
        (warehouse) => warehouse.ID_Warehouse != this.form.warehouseOrigin
      )
    },
    ...mapState('rolesUser', {
      rolesStatus: (state) => state.roles,
    }),
  },
  mounted() {
    this.$store.dispatch('rolesUser/getAllRoles')
    this.business =
      JSON.parse(localStorage.getItem('user')).businessid != undefined
        ? JSON.parse(localStorage.getItem('user')).businessid
        : 0
    this.branch =
      JSON.parse(localStorage.getItem('user')).branch != undefined
        ? JSON.parse(localStorage.getItem('user')).branch
        : 0
    this.filterProducts = {
      filter: 'pricelist',
      business: this.business,
      branch: this.branch,
    }
    this.getAllPriceList()
    this.getProducts()
    this.getInventory()
    this.getAllProducts()
    this.getFamilies()
    this.getCategories()
    this.getSubs()
    this.getAllProviders()
    this.search.business = JSON.parse(localStorage.getItem('user')).businessid
    this.search.branch = JSON.parse(localStorage.getItem('user')).branch
    this.search.type = 0
    this.getFamilies();
    this.getCategories();
    this.getSubs();
    this.getCustomers()
    this.getWarehouse()
  },

  methods: {
    // getFamilies() {
    //   this.$API.itemtypes.getFamiliesHistory().then((response) => {
    //     this.columsConfig[0].options = response;
    //   });
    // },
    // getCategories() {
    //   this.$API.itemtypes.getCategoriesHistory().then((response) => {
    //     this.columsConfig[1].options = response;
    //   });
    // },
    // getSubs() {
    //   this.$API.itemtypes.getSubsHistory().then((response) => {
    //     this.columsConfig[2].options = response;
    //   });
    // },
    // async selectFamily() {
    //   this.filteredCategories = await this.$API.itemtypes.getCategoriesByFamily( { id_family: this.form.family } );
    // },
    // async selectCategory() {
    //   this.filteredSubcategories = await this.$API.itemtypes.getSubsByCategory( { id_cat: this.form.category } );
    // },
    clearInventoryFilter() {
      // this.isInventoryFiltered = false;
      this.getInventory();
      this.getItemsFromWarehouseOrigin()
    },
    handleInventoryFilter(response) {
      if (response.length === 0 && this.didHaveAllData === false) {
        this.didHaveAllData = true;
        this.getInventoryListAll();
        this.$refs.generalFilter.filterHandler();
      }
      // this.isInventoryFiltered = true;
      //Filtering inventoryList
      this.inventory = response.filter(product => product.Warehouse == this.form.warehouseOrigin);
      //Filtering again inventory
      // this.getItemsFromWarehouseOrigin()
    },
    filterInventoryPromise(filterObj) {
      const filterArray =[
        "ID_Item",
        "ItemName",
        "WarehouseName",
        "ItemCurrentQuantity",
        "ItemMeasureUnit",
      ];
      return new Promise((resolve, reject) => {
        try {
          resolve(vesatileCriteriaFilter(filterArray, filterObj, this.inventory));
        } catch (error) {
          reject(error);
        }
      });
    },
    ...mapMutations({
      setReload: 'transferData/setReload'
    }),
    calculateStockResult(ref){
      for( const i in this.productsToShow ){
        this.productsToShow[i].StockResult = this.productsToShow[i].ItemCurrentQuantity - this.productsToShow[i].Amount;
      }
      this.$refs[ref].instance.refresh();
      // this.$refs['dxDataGridRequest'].instance.refresh();
    },
    onEditorChange(e) {
      this.form.comments = e.html
    },
    async handleStockAndQty(params) {
      try {
        var { value, data: { ItemCurrentQuantity } } = params

        if (value > ItemCurrentQuantity) {
          return false
        }
        
        for( const i in this.productsToShow ){
          this.productsToShow[i].StockResult = this.productsToShow[i].ItemCurrentQuantity - this.productsToShow[i].Amount;
        }

        return true
      } catch (error) {
        this.alert.show('danger', 'Error', `Ocurrio el siguiente error al intentar manejar el stock del producto ${error.message}`)
      }
    },
    calculateCellValue(data) {
      data.StockResult = data.ItemCurrentQuantity - data.Amount
      return data.StockResult
    },
    calculateCellValue2(data) {
      data.StockResultAtDestination = data.ItemCurrentQuantityAtDestination + data.Amount
      return data.StockResultAtDestination
    },
    async itemsQtyOnlyDigits(params) {
      try {
        const retorno = this.itemQtyPattern.test(params.value)
        return retorno
      } catch (error) {
        this.alert.show('danger', 'Error', `Ocurrio el siguiente error al intentar validar la cantidad del producto ${error.message}`)
      }
    },
    getCustomers() {
      this.$API.customers.getCustomers(this.search).then((response) => {
        this.customers = response
      })
    },
    onExporting(e) {
      printToExcel(e, 'Lista de Precios ')
    },
    async onExportingLP() {
      this.exportN()
      this.exportData = true
    },
    async exportN() {
      this.exportData = true
      setTimeout(() => {
        const data = this.$refs.priceListref.instance
        printToExcel({ component: data }, 'Lista de Productos')
      }, 200)
      setTimeout(() => {
        this.exportData = false
      }, 300)
    },
    onExportingP(e) {
      printToExcel(e, 'Lista de Traspasos')
    },
    filterProductsDt() {
      this.productsToBill = this.productsToShowOld
      if (this.filter.family != null) {
        this.productsToBill = this.productsToBill.filter(
          (product) => product.id_family == this.filter.family
        )
      }
      if (this.filter.category != null) {
        this.productsToBill = this.productsToBill.filter(
          (product) => product.id_cat == this.filter.category
        )
      }
      if (this.filter.subcategory != null) {
        this.productsToBill = this.productsToBill.filter(
          (product) => product.id_subcat == this.filter.subcategory
        )
      }
      if (this.filter.providers != null) {
        this.productsToBill = this.productsToBill.filter(
          (product) => product.ID_Provider == this.filter.providers
        )
      }
      this.showFilter = false
    },
    clearFilters() {
      this.filter.family = null
      this.filter.category = null
      this.filter.subcategory = null
      this.filter.providers = null
      this.productsToBill = this.productsToShowOld
    },
    async addProducts() {
      // ItemOriginalPrice
      this.inventory.forEach((element) => {
        if (element.checkbox) {
          this.productsprev.push(element)
        }
      })

      for (const element of this.productsprev) {
        if (!this.productsToShow.find((i) => i.ID_Inventory == element.ID_Inventory)) {
          let result = await this.$API.inventoryTransfer.infoProductAtDestination({
            warehouse: this.form.warehouseDestination,
            id_itembatch: element.ID_ItemBatch,
            id_item: element.ID_Item
          });

          let warehouseAtDestination = this.warehouses.find((warehouse) => warehouse.ID_Warehouse == this.form.warehouseDestination).Warehouse

          this.productsToShow.push({ ...element, Amount: 0, StockResult: element.ItemCurrentQuantity, 
            warehouseAtDestination: warehouseAtDestination, ItemCurrentQuantityAtDestination: result.ItemCurrentQuantity || 0, StockResultAtDestination: result.ItemCurrentQuantity || 0, ID_ItemBatchAtDestination: result.ID_ItemBatch || 'Por defecto' }) // <== HERE
        }
      }

      // Saving added products for transfer
      this.saveProductsToShow()

      //  this.productsToShow.push(...this.productsprev);
      this.inventory.map((element) => {
        element.checkbox = false
      })
      this.counter = 0
      this.showPrices = false
      this.showpriceskey++
      this.productsprev = []
    },

    closeAlert() {
      this.alert.show = false
    },

    reload() {
      this.getAllPriceList()
      this.getProducts()
      setTimeout(() => {
        window.scrollTo(0, this.$store.getters.getScrollTop)
        //alert(this.$store.getters.getScrollTop)
      }, 300)
    },
    showAlert(type, header, body, options = null) {
      type = type == null ? 'danger' : type
      this.alert.type = type
      this.alert.header = header
      this.alert.body = body
      this.alert.show = true
      this.alert.options = options != null ? options : null
    },
    deselectAll() {
      this.productsToBill.forEach((element) => {
        element.checkbox = false
      })
      this.showdeselect = false
      this.counter = 0
    },
    selectAll() {
      this.productsToBill.forEach((element) => {
        element.checkbox = true
      })
      this.counter = this.productsToBill.length
      this.showdeselect = true
    },
    getAllPriceList() {
      this.$API.priceList.priceList(this.filterProducts).then((response) => {
        this.priceList = response
      })
    },
    getProducts() {
      this.$API.priceList
        .getProductsList(this.filterProducts)
        .then((response) => {
          this.productsToBill = response
          this.productsToShowOld = response
          // this.productsToShowWarehouse = response
          // add a checkbox to each product
          this.productsToBill.forEach((product) => {
            product.checkbox = false
          })
        })
    },
    getInventory() {
      this.$API.products
        .getProductInventoryHistory()
        .then(response => {
          this.inventory = response
          this.productsToShowWarehouse = response
          this.fillDataFromTransfer()
        })
        .catch(error => {
          console.log(error)
        })
    },
    getInventoryListAll() {
      this.$API.products
        .getProductInventory()
        .then(response => {
          this.inventory = response
          this.productsToShowWarehouse = response
          this.fillDataFromTransfer()
        })
        .catch(error => {
          console.log(error)
        })
    },
    getAllProducts() {
      this.$API.products.getProductsHistory(this.search)
        .then(response => {
          this.products = response
          this.AllData = response
        })
    },
    getAllProviders() {
      let search = {}
      search.business =
        JSON.parse(localStorage.getItem('user')).businessid || ''
      search.branch = JSON.parse(localStorage.getItem('user')).branch || ''
      this.$API.providers.getProviders(search).then((response) => {
        this.providers = response
      })
    },
    showProduct() {
      this.counter = 0
      this.productsToBill.forEach((element) => {
        if (element.checkbox) {
          this.counter++
        }
      })
    },
    getFamilies() {
      this.$API.itemtypes.getFamilies().then((response) => {
        this.families = response
        this.columsConfig[0].options = response.map((fam) => ({name: fam.name, value: fam.id}));
      })
    },
    getCategories() {
      this.$API.itemtypes.getCategories().then((response) => {
        this.categories = response
        this.columsConfig[1].options = response.map((cat) => ({name: cat.name, value: cat.id, parent_id: cat.id_family}));
      })
    },
    getSubs() {
      this.$API.itemtypes.getSubs().then((response) => {
        this.subcategories = response
        this.columsConfig[2].options = response.map((sub) => ({name: sub.name, value: sub.id, parent_id: sub.id_cat}));
      })
    },
    handleChanged(priceListSelected) {
      this.showPriceList = false
      this.loading = true
      if (!priceListSelected.Active) {
        this.showAlert(
          'warning',
          'Advertencia',
          'Debe existir almenos una lista de precios activa'
        )
        this.priceList = this.priceList.map((i) => {
          if (i.id == priceListSelected.id) {
            i.Active = true
          }

          return i
        })
        return
      }
      let data = {
        id: priceListSelected.id,
      }
      this.$API.priceList
        .changuePriclistSelected(data)
        .then(() => {
          this.loading = false
          this.showAlert(
            'success',
            'Exito',
            'Lista de precios activada con exito'
          )
          this.$store.commit('setChanguePriceList', true)
          this.cleanData()
          this.getAllPriceList()
        })
        .catch(() => {
          this.loading = false
          this.showAlert(
            'danger',
            'Error',
            'Error al activar la lista de precios'
          )
          this.getAllPriceList()
        })
    },
    showProductOld(data) {
      this.update = true
      let params = {
        business:
          JSON.parse(localStorage.getItem('user')).businessid != undefined
            ? JSON.parse(localStorage.getItem('user')).businessid
            : 0,
        branch:
          JSON.parse(localStorage.getItem('user')).branch != undefined
            ? JSON.parse(localStorage.getItem('user')).branch
            : 0,
        PriceListName: data.data.id,
      }

      this.$API.priceList.itemsFromList(params).then((response) => {
        (this.productsToShow = response.map((i) => {
          return {
            ID_Item: i.ID_Item,
            ItemName: i.ItemName,
            ItemOriginalPrice: i.ItemOriginalPrice,
            ItemPriceDiscountPercent: i.ItemPriceDiscountPercent,
            ItemPriceDiscountAmount: i.ItemPriceDiscountAmount,
            ItemNewPrice: i.ItemNewPrice,
            ItemCurrency: i.ItemCurrency,
            ID_Branch: i.ID_Branch,
            IsGlobalBranch: i.IsGlobalBranch,
            ID_Business: i.ID_Business,
            Active: data.data.Active,
          }
        })),
          (this.form.pricelistname = data.data.id)
        this.form.Active = data.data.Active
      })
    },
    removeRow(data) {
      this.productsToShow.splice(data.rowIndex, 1)
    },
    resetData() {
      this.productsToShow = []
      this.form.pricelistname = null
      this.update = false
      this.getInventory()
    },
    changeDis(event, data) {
      let OriginalPrice = parseFloat(data.data.ItemOriginalPrice)

      data.data.ItemPriceDiscountPercent = (
        (parseFloat(event.target.value) / OriginalPrice) *
        100
      ).toFixed(2)

      data.data.ItemNewPrice = parseFloat(
        OriginalPrice - parseFloat(event.target.value)
      ).toFixed(2)

      this.productsToShow = this.productsToShow.map((i) => {
        if (i.ID_Item == data.data.ID_Item) {
          i.ItemPriceDiscountPercent = data.data.ItemPriceDiscountPercent
          i.ItemPriceDiscountAmount = data.data.ItemPriceDiscountAmount
          i.ItemNewPrice = data.data.ItemNewPrice
        }

        return i
      })
    },
    changeDisP(event, data) {
      let OriginalPrice = parseFloat(data.data.ItemOriginalPrice)

      data.data.ItemPriceDiscountAmount = (
        (parseFloat(event.target.value) / 100) *
        OriginalPrice
      ).toFixed(2)

      data.data.ItemNewPrice = parseFloat(
        OriginalPrice - parseFloat(data.data.ItemPriceDiscountAmount)
      ).toFixed(2)

      this.productsToShow = this.productsToShow.map((i) => {
        if (i.ID_Item == data.data.ID_Item) {
          i.ItemPriceDiscountPercent = data.data.ItemPriceDiscountPercent
          i.ItemPriceDiscountAmount = data.data.ItemPriceDiscountAmount
          i.ItemNewPrice = data.data.ItemNewPrice
        }

        return i
      })

    },
    acceptAlert() {
      this.alert.show = false

      if (this.alert.options == "clientLinkList") {
        this.addPriceListToClient()
      }
    },
    cleanData() {
      this.productsToShow = []
      this.productsToShowFilter = []
      this.filter = {}
      this.search = {
        filter: null,
        products: []
      },
      this.form.warehouseDestination = null
      this.form.warehouseOrigin = null
      this.form.typeOfTransfer = null
      this.form.Active = false
      this.update = false
      this.getAllPriceList()
      this.transferRequestActive = false
      // this.filteredCategories()
      this.requestStatus = null
      this.requestId = null
    },
    addNewTransfer() {
      try {

        this.loading = true
        if (!this.valid) {
          this.showAlert(
            'warning',
            'Advertencia',
            'Debe llenar los campos requeridos'
          )
          return
        }
        this.$refs['dxDataGridTransfer'].instance.refresh();
        const { warehouseOrigin, warehouseDestination, comments } = this.form

        const inventorytransfer = {
          TransferDate: fixFormatDate(this.date),
          OriginID_Warehouse: warehouseOrigin,
          DestinyID_Warehouse: warehouseDestination,
          ID_User: JSON.parse(localStorage.getItem('user')).id,
          Comments: comments,
          ID_Request: this.requestId,
          RequestStatus: this.requestStatus,
        }

        const inventorytransferdetails = [...this.productsToShow]

        const body = {
          inventorytransfer,
          inventorytransferdetails
        }

        this.$API.inventoryTransfer.addNewTransfer(body)
          .then(() => {
            this.loading = false
            this.showAlert(
              'success',
              'Exito',
              'Traspaso creado con exito'
            )
            this.resetData()
            this.cleanData()
          })
          .catch(error => {
            this.loading = false
            console.log(error)
            this.showAlert(
              'danger',
              'Error',
              'Error al guardar el traspaso ' + error.message
            )
          })
      } catch (error) {
        this.loading = false
        console.log(error)
        this.showAlert(
          'danger',
          'Error',
          'Error al guardar el traspaso' + error.message
        )
      }
    },
    addNewTransferRequest() {
      try {

        this.loading = true
        if (!this.valid) {
          this.showAlert(
            'warning',
            'Advertencia',
            'Debe llenar los campos requeridos'
          )
          return
        }
        this.$refs['dxDataGridRequest'].instance.refresh();
        const { warehouseOrigin, warehouseDestination, comments } = this.form

        const inventorytransferrequest = {
          RequestDate: fixFormatDate(this.date),
          OriginID_Warehouse: warehouseOrigin,
          DestinyID_Warehouse: warehouseDestination,
          ID_User: JSON.parse(localStorage.getItem('user')).id,
          Comments: comments
        }

        const inventorytransferrequestdetails = [...this.productsToShow]

        const body = {
          inventorytransferrequest,
          inventorytransferrequestdetails
        }

        this.$API.inventoryTransfer.addNewTransferRequest(body)
          .then(() => {
            this.loading = false
            this.showAlert(
              'success',
              'Exito',
              'Solicitud de Traspaso creado con exito'
            )
            this.resetData()
            this.cleanData()
          })
          .catch(error => {
            this.loading = false
            console.log(error)
            this.showAlert(
              'danger',
              'Error',
              'Error al guardar la Solicitud de Traspaso' + error.message
            )
          })
      } catch (error) {
        this.loading = false
        console.log(error)
        this.showAlert(
          'danger',
          'Error',
          'Error al guardar la Solicitud de Traspaso' + error.message
        )
      }
    },
    getWarehouse() {
      let idbusiness = JSON.parse(localStorage.getItem("user")).businessid || ""
      let idbranch = JSON.parse(localStorage.getItem("user")).branch || ""
      this.filterWarehouses.branch = idbranch
      this.filterWarehouses.business = idbusiness
      this.$API.warehouse.getWarehouses(this.filterWarehouses)
        .then(response => {
          this.warehouses = response
        })
    },
    getItemsFromWarehouseOrigin() {
      this.inventory = this.productsToShowWarehouse.filter(product => product.Warehouse == this.form.warehouseOrigin)
    },
    getItemsFilteredFromWarehouseOrigin() {
      this.inventoryFiltered = JSON.parse(JSON.stringify(this.inventory)).filter(product => product.Warehouse == this.form.warehouseOrigin)
    },
    fillDataFromTransfer() {

      if (this.transferRequestData.length == 0) {
        console.error("transferRequestData es nulo")
        return
      }

      const {
        OriginID_Warehouse,
        DestinyID_Warehouse,
        ID_Request,
        RequestStatus,
        details
      } = 
        JSON.parse(JSON.stringify(this.transferRequestData))

      // Using a map to get each detail
      const setCurentQuantity = details.map((detail) => {

        const { ItemCurrentQuantity, ItemMeasureUnit } = this.inventory.find((item) =>{
          return item.ID_Inventory === detail.ID_Inventory 
        }
        );

        return {
          ...detail,
          ItemCurrentQuantity,
          ItemMeasureUnit
        }
      })

      this.form.warehouseOrigin = OriginID_Warehouse
      this.form.warehouseDestination = DestinyID_Warehouse
      this.productsToShow = setCurentQuantity
      this.requestId = ID_Request
      // Status of the order
      // Pendiente == 0
      // Realizado == 1
      // Cancelado == 2
      if(RequestStatus == 0){
        this.requestStatus = 1 
      }else{
        this.requestStatus = RequestStatus
      }
      
      this.getItemsFromWarehouseOrigin()

      // Saving added products for transfer
      this.saveProductsToShow()
      
      if(this.reloadState){
        this.setReload(false)
        this.$refs['dxDataGridRequest'].instance.reload();
        console.log("reload");
        console.log(this.transferRequestData);
        if(this.transferRequestData.tab == 'request')
        this.tab = 'tab-1'
      }
    },
    filterHandler(response) {
      this.productsToShow = response
    },
    filterTransferProduct({filter, products}){

      let filteredProducts = products.filter((product) => 
        product.ID_Item.toString().includes(filter) ||
        product.ItemName.includes(filter) ||
        product.Amount.toString().includes(filter) ||
        product.ItemCurrentQuantity.toString().includes(filter)
      );

      return new Promise((resolve) => {
          resolve(filteredProducts)
      })
    },
    saveProductsToShow(){
      this.productsToShowFilter = JSON.parse(JSON.stringify(this.productsToShow))
      this.search = {
          products: JSON.parse(JSON.stringify(this.productsToShowFilter))
       }
    },
    getProductsToShow(){
       this.productsToShow = JSON.parse(JSON.stringify(this.productsToShowFilter))
    }
  },
}
</script>

<style scoped>
.fs-10 {
  font-size: 10px !important;
}

.input-group-prepend {
  display: flex;
}

.input-group-prepend {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 3px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0 5px;
  font-size: 14px;
  line-height: 1;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input-group>.form-control {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}

.box {
  border-style: solid;
  border-color: black;
  border-width: 1px;
  background-color: white;

  border-radius: 15px;
}

.tabs:hover {
  background-color: rgba(80, 80, 80, 1);
  cursor: pointer;
}

.btnclean {
  background-color: #f29d35 !important;
  color: white;
}

.btn-add {
  background-color: #3498db !important;
  color: white;
}

.btnsave {
  background-color: #00a178 !important;
  color: white;
}

.tabs {
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white;
}

.btndelete {
  background-color: #e3191e !important;
  color: white;
}

.tabsNh {
  width: 100%;
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 10px;
}

.tabsB {
  width: 100%;
  padding: 10px;
  background-color: #1976d2;
  color: white;
  border-radius: 15px;
  border-style: solid;
  border-color: black;
  border-width: 1px;
}

.tabsContent {
  width: 100%;
  padding: 10px;
  background-color: #F29D35;
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;

  border-radius: 10px;

}

.tabs {
  width: 100%;
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;

  border-radius: 10px;

}

.tabsB:hover {
  background-color: #0d47a1;
  cursor: pointer;
}

.tabsN {
  padding: 5px;
  background-color: rgba(60, 60, 60, 1);
  color: white;
  border-radius: 15px;
}

.icon-color {
  color: white
}
</style>